import * as React from "react";
import Layout from "../../components/layout";
import Block from "../../components/block";
import styled from "styled-components";
import Footer from "../../components/footer";
import { BREAKPOINTS } from "../../consts";
import { useMediaQuery } from "react-responsive";
import TextPageContainer from "../../components/text-page-container";


const Page = styled.div`
  article {
    ul {
      list-style: none;
    }
    h2 {
      margin-top: 1rem;
    }
  }
`;

const PrivacyPolicyPage = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  return (
    <Page>
      <Layout title={"Privacy Policy"} description={"Privacy Policy of Bifrost Wallet."}>
        <Block fullHeight={true}>
          <TextPageContainer>
            <h1 className="page-title">Privacy Policy</h1>
            <p className="detail">Last updated: January 8, 2023</p>
            <div>
              <p>
                Towo Labs AB (“we”, “us”, “our” or “Towo Labs”) values your
                privacy. We strive to protect your personal data in the best
                possible way. When you use our non-custodial crypto asset
                software Bifrost Wallet, we do not process your personal data
                other than to:
              </p>
              <ol>
                <li>
                  Develop, maintain and improve our software and services
                </li>
                <li>
                  Respond to your support requests, questions or feedback
                </li>
                <li>
                  Provide you with updates and information on our websites
                </li>
                <li>To defend ourselves from any claims or complaints</li>
              </ol>
              <p>
                To ensure secure and lawful processing of your personal data,
                Bifrost Wallet is developed in accordance with the principles of
                privacy by design and data minimization. It means, in practice,
                that we are always thinking about your privacy when designing
                our software and services and that we do not process any
                personal data not necessary for the specific purpose.
              </p>
              <p>
                This policy applies to any personal data we collect when you as
                user uses our mobile application Bifrost Wallet (“Bifrost
                Wallet”) and/or are visiting our website. Words with capital
                letters in this Privacy Policy shall have the same meaning as in
                Bifrost Wallet Terms of Use.
              </p>
              <h2>Who is responsible for processing your personal data?</h2>
              <p>
                Towo Labs AB, a company duly incorporated under Swedish law with
                registration number 559209-4873 is responsible for the processing of your
                personal data that we process (the controller).
              </p>
              <h2>Who do we share your personal data with and why?</h2>
              <p>
                Your personal data is initially collected and processed by Towo
                Labs. As a principle, we do not share your data with third
                parties other than when we hire suppliers, for example
                IT-suppliers providing cloud services to us. The suppliers will
                only get access to your personal data to the extent necessary in
                order for them to fulfill their obligations in relation to Towo
                Labs. The suppliers will only process your personal data on
                behalf of Towo Labs as data processors.
              </p>
              <p>
                Furthermore, your personal data may be controlled and processed
                by other data controllers, such as but not limited to
                participants in the relevant blockchain networks and/or
                third-party service providers that you access within Bifrost
                Wallet. We are not involved in any transaction you may
                participate in, meaning we are neither a joint controller nor a
                data processor in such cases.
              </p>
              <h2>
                Do we transfer your personal data outside of the European Union?
              </h2>
              <p>
                We, as well as our processors, mainly process and store your
                personal data within EU/EEA. For the transfer of your personal
                data outside of the EU/EEA, we have entered into standard
                contractual clauses with our IT-suppliers or taken other
                appropriate safeguards (if there is no adequacy decision taken
                by the EU Commission) that we may base our transfer and our
                suppliers' processing upon.
              </p>
              <h2 className="intercom-align-left">
                From whom do we obtain your personal data?
              </h2>
              <p>
                We only obtain your personal data directly from you.
              </p>
              <h2 className="intercom-align-left">
                What are your rights when we process your personal data?
              </h2>
              <p>
                Under applicable data protection legislation, depending on the
                circumstances, you are entitled to a variety of rights when we
                process your personal data.
              </p>
              <h2>Right to object to processing</h2>
              <p>
                You have a right to object to our processing of your personal
                data when the processing is based on the legal basis “legitimate
                interest”. The situations when we base our processing on our
                legitimate interest are stated in the sections below. In some
                instances, we may continue to process your personal data even if
                you have objected to our processing. This can be the case if we
                can show compelling legitimate reasons for the processing that
                outweigh your interests or if it is for the purpose of
                establishing, exercising or defending legal claims.
              </p>
              <h2>
                Right to information and access
              </h2>
              <p>
                You have the right to obtain confirmation as to whether or not
                we are processing personal data about you and information about
                the personal data that we process, such as the purposes of the
                processing, categories and receipts of your personal data, and
                for how long we store your personal data. You can gain access to
                this information and obtain a copy of the personal data
                processed by us by contacting us as described below.
              </p>
              <h2>Right to rectification</h2>
              <p>
                You have a right to correct or update any inaccurate personal
                data concerning you that we may be processing and to ask us to
                have incomplete personal data completed. We may need to verify
                the accuracy of the new data you provide to us.
              </p>
              <h2>Right to erasure</h2>
              <p>
                Under certain circumstances, you have a right to request that we
                delete your personal data. This is the case for example where
                the personal data is no longer necessary for the purposes for
                which they were collected or otherwise processed, or you
                withdraw your consent on which the processing is based and where
                there is no other legal ground for our continued processing of
                your personal data.
              </p>
              <p>
                You also have a right to request that we restrict our processing
                of your personal data. This is the case for example when the
                accuracy of the personal data is contested by you, or the
                processing is unlawful, but you do not want us to delete it and
                instead you request that we restrict our use of it.
              </p>
              <h2>Right to lodge a complaint</h2>
              <p>
                You always have the right to lodge a complaint with a
                supervisory authority. You may do this in particular in the
                EU/EEA member state where you live, work or where there is an
                alleged infringement of the applicable data protection laws. The
                supervisory authority in Sweden is the Swedish Authority for
                Privacy Protection (IMY). You also have the right to seek a
                remedy or other decision from a national court.
              </p>
              <h2>Right to data portability</h2>
              <p>
                You have a right to ask us to transfer any of the personal data
                we have about you to another company, where technically feasible
                (“data portability”), provided that our processing is based on
                consent, or on a contract and our processing is carried out by
                automated means.
              </p>
              <h2 className="intercom-align-left">
                Data processing details and data retention
              </h2>
              <p>
                Here you can read more about how and to which extent we process
                your personal data, which categories of personal data we process
                and our legal basis for processing your personal data. You can
                also read for how long we process your personal data for each
                purpose.
              </p>
              <h2>When you use Bifrost Wallet</h2>
              <p>
                As a basis, we do not process your personal data when you use
                our Bifrost Wallet. Bifrost Wallet does not have any user
                accounts and we do not ask for any user data. Any information in
                Bifrost Wallet, such as your PIN code, your Private Keys and
                your Recovery Phrase, are stored on your mobile device and are
                not processed by us. We can neither access your Bifrost Wallet
                and Crypto assets nor get hold of your personal data, if any, in
                any other way. When you are adapting Bifrost Wallet to your
                settings and preferences, as well as when you customize Bifrost
                Wallet, such settings are solely stored on your mobile device.
                You are deciding how to process your personal data. In other
                words, we are not processing the data in Bifrost Wallet for you,
                only providing you with the infrastructure.
              </p>
              <h2>
                When we improve Bifrost Wallet and our Services
              </h2>
              <p>
                We may collect anonymized and aggregated data from Bifrost
                Wallet in order to develop, maintain and improve our Services.
                Anonymized data are data which are not considered personal data,
                as we cannot identify you based on the data and/or identify
                you/your data even if we get additional information from you.
                The data we process in order to develop and improve Bifrost
                Wallet and our services may be information about your version of
                Bifrost Wallet, bug-reporting information, and model of phone or
                operating system.
              </p>
              <h2>When you visit our websites</h2>
              <p>
                We may process your personal data when you visit any of our
                websites towolabs.com, bifrostwallet.com or other related
                subdomains. The purpose is to give you a decent user experience
                and answer your support requests. Your data may also be used in
                an aggregated format for the purpose of evaluating, developing
                and improving our services.
              </p>
              <p>
                <span className="bold">Personal data we process: </span>IP
                address, user agent, functional cookies, time and date of your visit.
              </p>
              <p>
                <span className="bold">Legal basis: </span>The processing is
                justified by our legitimate interest to enable you to visit our
                websites and to provide you with information and necessary
                features through the websites. The processing of cookies may be
                based on your consent to our processing. Please note that you
                may withdraw your consent at any time by contacting us at
                support@bifrostwallet.com, which will not affect the legality of
                the processing performed before the withdrawal. The storage of
                cookies may be directed through your browser settings as well.
              </p>
              <p>
                <span className="bold">Retention time: </span>The personal data is
                processed during the duration of your visit, and thereafter
                until the cookies' expiration date, in no case longer than 9
                months.
              </p>
              <h2>
                When you contact us for support
              </h2>
              <p>
                We may process your personal data to provide you with support,
                answer your questions, administrate your request and improve our
                services.
              </p>
              <p>
                <span className="bold">Personal data we process: </span>e-mail
                address, other personal data you provide us.
              </p>
              <p>
                <span className="bold">Legal basis: </span>The processing is
                necessary for performance of contract concerning your use of the
                Bifrost Wallet and our Services. If the data is not provided to
                us, we will not be able to give you support and service. If you
                contact us without having entered into a contract with us by
                accepting our Terms, we will process your personal data on the
                basis of our legitimate interest, namely to provide you with
                answers of your requests.
              </p>
              <p>
                <span className="bold">Retention time: </span>The personal data is
                processed until you have received the support you need and/or
                until we have responded to your request, but in no case longer
                than 90 days. If you have contacted us via the customer chat
                function at support.bifrostwallet.com, we may process your data
                longer (see “When you visit our websites” above).
              </p>
              <h2>
                If you complain, make a claim, or want to exercise your rights
                as a data subject
              </h2>
              <p>
                We may collect your data to handle your rights as a data
                subject, complaints or other claims.
              </p>
              <p>
                <span className="bold">Personal data we process: </span>Name,
                postal address, e-mail address, information from our
                communication with you regarding your complaint or claim, other
                information which contain your personal data (if any).
              </p>
              <p>
                <span className="bold">Legal basis: </span>The processing is
                necessary in order for us to act in accordance with law and to
                comply with a legal obligation. We also have a legitimate
                interest in being able to defend ourselves against a possible
                legal complaint or claim.
              </p>
              <p>
                <span className="bold">Retention time: </span>The personal data is
                processed from the time you submit your complaint or claim and
                is processed as long as the process of the complaint or claim is
                in progress.
              </p>
              <h2 className="intercom-align-left">
                Balance of interests' tests when processing data based on the
                lawful basis "legitimate interests"
              </h2>
              <p>
                As we state above, for some purposes, we process your personal
                data based upon our "legitimate interest". By carrying out a
                balancing of interests' assessment concerning our processing of
                your personal data, we have concluded that our legitimate
                interest for the processing outweighs your interests or rights
                which require the protection of your personal data. If you want
                more information in relation to our balancing of interests'
                assessments, please do not hesitate to contact us.
              </p>
              <h2 className="intercom-align-left">
                How do we protect your personal data?
              </h2>
              <p>
                We, and when applicable our partners and suppliers, have taken
                several security measures to protect the personal data that is
                processed. For example:
              </p>
              <ol>
                <li>
                  Awareness program and employee trainings
                </li>
                
                <li>
                  Encryption at rest and in transit
                </li>
                
                <li>
                  Hardened server configurations
                </li>
                
                <li>
                  Design software in accordance with privacy by design and data minimization principles
                </li>
                
                <li>
                  Continuous system monitoring
                </li>
                
                <li>
                  Independent security audits
                </li>
              </ol>
              <p>
                Although we are doing our best in order to protect your data
                (not only your personal data), you remain responsible for
                keeping your credentials, passwords, PIN code, Private Keys and
                Recovery Phrase confidential and secure. We cannot access or
                retrieve any such information for you.
              </p>
              <h2 className="intercom-align-left">
                Changes to the Privacy Policy?
              </h2>
              <p>
                We may modify or update this document when necessary, to reflect
                customer feedback and changes in Bifrost Wallet and our
                Services. Please review it regularly. If there are material
                changes to the Privacy Policy or in how we use your personal
                data, we will inform you by posting a notice of such changes in
                Bifrost Wallet, prior to them taking place. If you don't like
                our changes you can always terminate your contract with us.
              </p>
              <p>
                This privacy policy was adopted by Towo Labs AB on January 8,
                2023.
              </p>
              <p></p>
            </div>
          </TextPageContainer>
        </Block>
      </Layout>
      <Footer />
    </Page>
  );
};

export default PrivacyPolicyPage;
